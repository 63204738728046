body {
  margin: 0;
  overflow-x: hidden;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F4F4F4;
}

.Mui-expanded {
  margin: 0!important;
}
.MuiExpansionPanelSummary-root.Mui-expanded {
  border-color: #c5c5c5;
  border-style: solid;
  border-width: 1px 0px 1px 0px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

canvas {
  display: block;
}

.MuiTypography-overline {
  text-transform: inherit!important;
  font-size: 0.9rem!important;
}